// Gatsby supports TypeScript natively!
import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Formik, Field, ErrorMessage, Form } from 'formik';
import { string, object } from 'yup';
import axios from 'axios';
import { navigate } from '@reach/router'

const ContactUs = () => {
  const [errorState, setErrorState] = useState(false);
  const data = useStaticQuery(graphql`
    {
      mobileAndUpHeaderImage: file(relativePath: {eq: "contact-us@3x.png"}) {
        childImageSharp {
          fixed(width: 133, height: 118, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      tabletAndUpHeaderImage: file(relativePath: {eq: "contact-us@3x.png"}) {
        childImageSharp {
          fixed(width: 221, height: 196, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Contact Us" />
      <header className="page-header">
        <div className="header-image-container tablet-and-up">
          <Image fixed={data.tabletAndUpHeaderImage.childImageSharp.fixed} />
        </div>
        <div className="header__inner-container">
          <h1>Contact Us</h1>
          <div>
            <p>Whether you have questions about features, trials, pricing, need a demo, or anything else, our team is ready to answer all your questions.</p>
          </div>
          <Image className="mobile-to-tablet" fixed={data.mobileAndUpHeaderImage.childImageSharp.fixed} />
        </div>
      </header>
      <section className="content highlighted">
        <h2 className="flush-button">Get in Touch</h2>
        <p>We’ll be happy to hear from you! Please use the form below or email us at <a href="mailto:info@prasthana.com">info@prasthana.com</a></p>
        <section>
          <Formik
            initialValues={{ contactName: '', contactEmail: '', contactMessage: '' }}
            validationSchema={object().shape({
              contactName: string().required('Please enter your full name'),
              contactEmail: string().required('This field is mandatory').email('Must be a valid email address')
            })}
            onSubmit={(values, { setSubmitting }) => {

              let payload = new FormData()
              payload.set("apiType", "send-contact")
              payload.set("applyName", values.contactName)
              payload.set("applyEmail", values.contactEmail)
              payload.set("applyMessage", values.contactMessage)

              axios({
                method: "post",
                url: "https://www.prasthana.com/api/",
                data: payload,
                headers: { "Content-Type": "multipart/form-data" },
              }).then(function (response) {
                //handle success
                setSubmitting(false);
                navigate('contact-us/thank-you');
              }).catch(function (e) {
                //handle error
                setErrorState(e.message)
              });
            }}
          >
            {({ isSubmitting, values, errors, touched }) => {
              console.log(touched);
              return (
                <Form>
                  <div id="cstm-ak" className={`form-control${values.contactName !== '' ? ' is-dirty' : ''}${errors.contactName && touched.contactName ? ' has-errors' : ''}`}>
                    <Field id="contactName" type="text" name="contactName" />
                    <label htmlFor="contactName">Your name</label>
                  </div>
                  <ErrorMessage className="form-error" name="contactName" component="div" />
                  <div id="cstm-ak" className={`form-control${values.contactEmail !== '' ? ' is-dirty' : ''}${errors.contactEmail && touched.contactEmail ? ' has-errors' : ''}`}>
                    <Field id="contactEmail" type="email" name="contactEmail" />
                    <label htmlFor="contactEmail">Your email address</label>
                  </div>
                  <ErrorMessage className="form-error" name="contactEmail" component="div" />
                  <div className={`form-control textarea${values.contactMessage !== '' ? ' is-dirty' : ''}`}>
                    <Field id="contactMessage" as="textarea" placeholder="Type message here" name="contactMessage" />
                  </div>
                  {errorState &&
                    <div className="error-block">
                      {errorState}: Please try again later
                    </div>
                  }
                  <button className="button secondary is-large" type="submit" disabled={isSubmitting}>
                    Send
                  </button>
                </Form>
              )
            }}
          </Formik>
        </section>
        <h2 className="flush-button">Our Location</h2>
        <p>1-58/5/A, 3rd Floor, Above Bank of India, Opp.Furniture City, Gachibowli X roads, Hyderabad, Telangana - 500031.</p>
        <section>
        </section>
      </section>
      <section className="flush-bottom">
        <iframe className="map" src="https://maps.google.com/maps?q=Prasthana%20Software&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder="0" style={{border:0}} aria-hidden="false" tabIndex={0}></iframe>
      </section>
    </Layout>
  )
}


export default ContactUs